import { useCallback } from 'react'
import { PERMISSIONS } from '../../src/permissions'
import { useGetLoggedInUsersPermissionsQuery } from '../../gql/generated'

export default function usePermissions() {
  const { data, error, loading } = useGetLoggedInUsersPermissionsQuery()

  const hasPermission = useCallback(
    (permission: PERMISSIONS): boolean => {
      if (loading) return false
      return data.me.permissions.includes(permission)
    },
    [data, loading]
  )

  return {
    loading,
    error,
    hasPermission,
  }
}
