import { CircularProgress } from '@mui/material'

interface LoaderProps {
  color?: string
  size?: number | string
}

export default function Loader({ color, size }: LoaderProps) {
  return (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress style={{ color }} size={size} />
    </div>
  )
}

export function TextLoader() {
  return <>Loading...</>
}
