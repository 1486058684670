import Loader from '../loader'
import ErrorMessage from '../error-message'
import React from 'react'
import {
  ACCOUNT_MODULE_LIST,
  ECM,
  INVENTORY,
  ME,
  POS,
  REPORTING,
} from '../../src/routes'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import Button from '@mui/material/Button'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import { useRouter } from 'next/router'
import Icon from '@mdi/react'
import {
  mdiAccountMultiple,
  mdiCashRegister,
  mdiFactory,
  mdiInformationOutline,
} from '@mdi/js'
import AssessmentIcon from '@mui/icons-material/Assessment'

import {
  PERMISSION_ROOT,
  PERMISSION_VIEW_ECM,
  PERMISSION_VIEW_INVENTORY,
  PERMISSION_VIEW_NPOS,
  PERMISSION_VIEW_REPORTING,
  PERMISSIONS,
} from '../../src/permissions'
import usePermissions from '../../hooks/permissions/use-permissions'

export const useAppBarStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      flexGrow: 1,
    },

    menuButton: {
      marginRight: theme.spacing(1),
    },

    title: {
      flexGrow: 1,
    },
  }
})

interface NavItem {
  id: number
  permission?: PERMISSIONS
  title: string
  url:
    | typeof ACCOUNT_MODULE_LIST
    | typeof INVENTORY
    | typeof ME
    | typeof POS
    | typeof ECM
    | typeof REPORTING
  debugOnly?: boolean
  icon?: JSX.Element
}

const navItems: NavItem[] = [
  {
    id: 1,
    permission: PERMISSION_ROOT,
    title: 'Modules',
    url: ACCOUNT_MODULE_LIST,
    icon: <ViewModuleIcon />,
  },
  {
    id: 2,
    permission: PERMISSION_VIEW_NPOS,
    title: 'POS',
    url: POS,
    icon: <Icon path={mdiCashRegister} size={1} />,
  },
  {
    id: 3,
    permission: PERMISSION_VIEW_INVENTORY,
    title: 'Inventory',
    url: INVENTORY,
    icon: <Icon path={mdiFactory} size={1} />,
  },
  {
    id: 5,
    permission: PERMISSION_VIEW_ECM,
    title: 'ECM',
    url: ECM,
    icon: <Icon path={mdiAccountMultiple} size={1} />,
  },
  {
    debugOnly: true,
    id: 4,
    title: 'Debug Information',
    url: ME,
    icon: <Icon path={mdiInformationOutline} size={1} />,
  },
  {
    id: 6,
    permission: PERMISSION_VIEW_REPORTING,
    title: 'Reports',
    url: REPORTING,
    icon: <AssessmentIcon />,
  },
]

export default function AppBarButtons() {
  const { classes } = useAppBarStyles()

  const router = useRouter()
  const { hasPermission, loading, error } = usePermissions()

  if (loading) return <Loader color="white" />
  if (error) return <ErrorMessage error={error} />

  const showDebugPage = router.query.debug === 'true'
  const showNavItem = (permission: PERMISSIONS, debugOnly: boolean) => {
    if (showDebugPage && debugOnly) return true
    return hasPermission(permission)
  }
  return (
    <>
      {navItems.map(({ id, permission, title, url, debugOnly, icon }) => {
        if (!showNavItem(permission, debugOnly)) return null

        return (
          <Button
            id={title.replace(/\s/g, '') + 'AppBarBtn'}
            name="AppBarBtn"
            key={id}
            style={{ marginLeft: 10 }}
            color={router.route.includes(url) ? 'info' : 'inherit'}
            startIcon={icon}
            onClick={() => router.push({ pathname: url })}
          >
            {title}
          </Button>
        )
      })}
    </>
  )
}
