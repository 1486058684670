import { AppBar, Button, Divider, Toolbar, Typography } from '@mui/material'
import React from 'react'
import AppBarButtons, { useAppBarStyles } from './app-bar-buttons'
import { LOGIN } from '../../src/routes'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useRouter } from 'next/router'
import { useApolloClient } from '@apollo/client'

export default function CustomAppBar({ title }) {
  const { classes } = useAppBarStyles()
  const router = useRouter()
  const client = useApolloClient()

  return (
    <div className={classes['root']}>
      <AppBar
        id="appBarHeader"
        position="static"
        className={classes['appBar']}
        style={{ minHeight: 40 }}
      >
        <Toolbar style={{ minHeight: 40, width: '100%' }}>
          <Typography className={classes['title']} variant="h6">
            {title}
          </Typography>
          <AppBarButtons />
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginLeft: 20, marginRight: 20 }}
          />
          <Button
            id="LogoutAppBarBtn"
            name="AppBarBtn"
            color="inherit"
            startIcon={<ExitToAppIcon />}
            onClick={() => {
              const token = localStorage.getItem('idtoken')
              localStorage.clear()
              // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
              client.clearStore()
              window.location.href =
                `https://${process.env.NEXT_PUBLIC_OKTA_DOMAIN}/v1/logout?id_token_hint=` +
                token +
                `&post_logout_redirect_uri=${process.env.NEXT_PUBLIC_OKTA_LOGOUTREDIR}`
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}
