import { Container, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import CustomAppBar from './app-bar'
import { ReactNode } from 'react'
const useStyles = makeStyles()((theme: Theme) => {
  return {
    pageTitle: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
    },
  }
})

interface LayoutWithTitleProps {
  title: string
  children: ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export default function LayoutWithTitle({
  children,
  title,
  maxWidth = 'md',
}: LayoutWithTitleProps) {
  const { classes } = useStyles()
  const accountTitle =
    typeof window !== 'undefined' && localStorage.getItem('accountName')

  return (
    <>
      <CustomAppBar title={accountTitle} />
      <Container maxWidth={maxWidth}>
        <Typography variant="h3" className={classes.pageTitle}>
          {title}
        </Typography>
        {children}
      </Container>
    </>
  )
}
