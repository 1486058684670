import LayoutWithTitle from '../components/layout-with-title'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Paper, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => {
  return {
    container: { textAlign: 'center', padding: 20 },
    icon: { fontSize: '15em' },
  }
})

export default function Custom404Page() {
  const { classes } = useStyles()
  return (
    <LayoutWithTitle title="Page Not Found">
      <Paper className={classes.container}>
        <ErrorOutlineIcon className={classes.icon} />
        <Typography variant="h2">
          We couldn&apos;t find what you were looking for.
        </Typography>
      </Paper>
    </LayoutWithTitle>
  )
}
