import { Alert } from '@mui/material'
import { ApolloError } from '@apollo/client'
import { Typography } from '@mui/material'

interface ErrorProps {
  loading?: boolean // TODO: Let's phase this out, why did we add it in the first place
  error: ApolloError
}

interface NetworkErrorResultItem {
  message: string
}

interface NetworkErrorResult {
  errors: NetworkErrorResultItem[]
}

interface NetworkError {
  result: NetworkErrorResult
}

export default function ErrorMessage({ loading, error }: ErrorProps) {
  if (loading || !error) return null

  console.log('error', error.graphQLErrors)

  const networkError = error.networkError as unknown as NetworkError

  return (
    <Alert severity="error">
      {error.graphQLErrors.map((error, i) => (
        <>
          <Typography key={i}>{error.message}</Typography>
          {i > 0 && <br />}
        </>
      ))}
      {networkError?.result?.errors.map((error, i) => (
        <>
          <Typography>{error.message}</Typography>
          {i > 0 && <br />}
        </>
      ))}
    </Alert>
  )
}
