export const PERMISSION_EDIT_WORKFLOW = 'edit.workflow'
export const PERMISSION_VIEW_NPOS = 'view.npos'
export const PERMISSION_VIEW_INVENTORY = 'view.inventory'
export const PERMISSION_VIEW_ECM = 'view.customer'
export const PERMISSION_EDIT_INVADJ = 'edit.inventory.inventory-adjustment'
export const PERMISSION_EDIT_ITEMMAINT = 'edit.inventory.item-maintenance'
export const PERMISSION_ROOT = 'root'
export const PERMISSION_EXECUTE_DEFAULTITEM = 'execute.npos.set-default-item'
export const PERMISSION_VIEW_REPORTING = 'view.reporting'

export type PERMISSIONS =
  | typeof PERMISSION_EDIT_WORKFLOW
  | typeof PERMISSION_VIEW_NPOS
  | typeof PERMISSION_VIEW_INVENTORY
  | typeof PERMISSION_VIEW_ECM
  | typeof PERMISSION_EDIT_INVADJ
  | typeof PERMISSION_EDIT_ITEMMAINT
  | typeof PERMISSION_ROOT
  | typeof PERMISSION_EXECUTE_DEFAULTITEM
  | typeof PERMISSION_VIEW_REPORTING
